import rollingBanner1Web from '../assets/img/banners/CanadaJetlines/1920x400.jpg';
import rollingBanner1Tab from '../assets/img/banners/CanadaJetlines/iPad_WL_Home [Banner4].jpg';
import rollingBanner1Mob from '../assets/img/banners/CanadaJetlines/Phone_WL_Home [Banner1].jpg';
import { default as logo, default as logoWhite } from '../assets/img/logos/canadajetlines.png';
import localization from '../localization';
var banner = require('../assets/img/banners/CanadaJetlines/1920x400.jpg');
var favicon = require('../assets/img/Pin.svg');

export default {
  name: 'Canada Jetlines',
  clientName: 'Canada Jetlines',
  config: {
    showMapsByDefault: false,
    googleAnalyticsKey: '',
    clientGAKey: '',
    GTMID: '',
    usePrimaryColoredBanner: false,
    overrideActivityProviderText: 'Activity brought to you by Canada Jetlines',
    priorityPrefixCountries: ['ca', 'us'],
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#ff671f',
    secondary: '#002855',
    inactive: '#BCBCBC',
  },
  fonts: {
    colors: {
      primary: '#ff671f',
      secondary: '#002855',
      inactive: '#BCBCBC',
    },
    primary: 'MontserratRegular',
    regular: 'MontserratRegular',
    light: 'MontserratRegular',
    bold: 'MontserratBold',
  },
  styles: {
    airserbiaButton: {
      borderRadius: '4px!important',
    },
    pickerControlButton: {
      background: '#0072CE',
      borderRadius: '4px',
      width: '32px',
      height: '32px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    pickerCounter: {
      background: 'none',
      border: '0',
      width: '36px',
      height: '32px',
      padding: '0',
      textAlign: 'center',
      margin: '0 2px',
    },
  },
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },

  assets: {
    logo,
    logoWhite,
    favicon,
    banner,
  },
  currency: {
    default: 'USD $',
    defaultWithSymbol: 'USD $',
    available: {
      CAD: 'CAD $',
      USD: 'USD $',
    },
  },
  language: {
    default: 'en',
    available: ['en', 'fr-CA'],
  },
};
