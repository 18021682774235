import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import InstantConfirmation from '../../assets/img/icons/InstantConfirmation.svg';
import MobileTicket from '../../assets/img/icons/MobileTicket.svg';
import MoneyBack from '../../assets/img/icons/MoneyBackGuarantee.svg';
import ReviewedByPeers from '../../assets/img/icons/ReviewedByPeers.svg';
import localization from '../../localization';
import './dotStyles.css';
import TrustBuilder from './TrustBuilder';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 560 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 560, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const useStyles = makeStyles((theme) => ({
  container: { marginTop: '40px', textAlign: 'left' },
  mobileContainer: {
    width: 'unset',
    minHeight: '100px',
    margin: 'auto',
  },
  fullWidth: { width: '100%' },
  unsetPaddingLeft: { paddingLeft: 'unset' },
  smallTopMargin: { marginTop: '8px' },
  flexIconsContainer: {
    display: 'none',
    marginTop: '40px',
    textAlign: 'left',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'flex',
    },
  },
  carouselContainer: {
    display: 'block',
    marginTop: '40px',
    textAlign: 'left',

    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none',
    },
  },
}));

export default function TrustBuilders() {
  const classes = useStyles();
  let trustBuilders = [
    {
      img: MoneyBack,
      title: localization.trustBuilders.moneyBack.title,
      text: localization.trustBuilders.moneyBack.text,
    },
    {
      img: InstantConfirmation,
      title: localization.trustBuilders.instantConfirmation.title,
      text: localization.trustBuilders.instantConfirmation.text,
    },
    {
      img: MobileTicket,
      title: localization.trustBuilders.mobileTicket.title,
      text: localization.trustBuilders.mobileTicket.text,
    },
    {
      img: ReviewedByPeers,
      title: localization.trustBuilders.reviewedByPeers.title,
      text: localization.trustBuilders.reviewedByPeers.text,
    },
  ];

  return (
    <>
      <div className={classes.carouselContainer}>
        <Carousel
          swipeable={true}
          showDots={true}
          responsive={responsive}
          infinite={true}
          draggable={true}
          arrows={false}
          autoPlaySpeed={999999}
          partialVisible={true}
          deviceType={
            isMobile ? 'mobile' : window.innerWidth < StyleBreakpoints.lg ? 'tablet' : 'desktop'
          }
          keyBoardControl={true}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
        >
          {trustBuilders.map((item) => {
            return <TrustBuilder key={item} item={item} />;
          })}
        </Carousel>
      </div>

      <div className={classes.flexIconsContainer}>
        {trustBuilders.map((item, i) => {
          return <TrustBuilder key={`${item}-${i}`} item={item} />;
        })}
      </div>
    </>
  );
}
