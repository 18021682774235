import { FontSizes, StyleBreakpoints } from '@constants';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../../../../localization';
import { getLangName, isiOS, wrapedSynthetichandler } from '../../../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  paper: {
    borderRadius: '7px',
    marginTop: '8px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      borderRadius: '0px',
      backgroundColor: theme.palette.background.dark,
      position: 'absolute',
      left: '0!important',
      top: 'auto!important',
      right: 0,
      bottom: 0,
      height: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100vw',
      color: '#fff',
    },
  },
  selectLangBlock: {
    width: '360px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    '& ::-webkit-scrollbar': {
      width: '4px',
      paddingRight: '2px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      width: '4px',
      borderRadius: '2px',
    },
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      width: '100%',
      color: '#fff',
    },
  },
  selectLangBlockTitle: {
    fontSize: '15px',
    fontWeight: '700',
    textAlign: 'center',
    margin: '20px',
  },
  radioButtonsBlock: {
    width: '100%',
    padding: '0 20px',
    overflowY: 'auto',
  },
  selectBtnBlock: {
    marginTop: 'auto',
    padding: '0 20px',
    textAlign: 'center',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      width: '256px',
      margin: 'auto auto 0',
    },
  },
  inputRoot: {
    fontSize: FontSizes.cta,
    '& label.Mui-focused': {
      color: theme.palette.text.grey,
    },
    '& label': {
      color: theme.palette.text.grey,
    },
  },
  selectLabel: {
    color: theme.palette.text.grey,
    '&.Mui-focused': {
      color: theme.palette.text.grey,
    },
  },
  select: {
    fontSize: isiOS() ? '16px' : '14px',
  },
}));

function LanguagePicker({
  languages,
  selectedLanguage,
  setSelectedLanguage,
  anchorEl,
  nextAnchor,
  setAnchorEl,
  langRef,
  isMobileView,
  onClose,
}) {
  if (!languages) {
    languages = [];
  }
  const classes = useStyles();
  const open = Boolean(anchorEl && anchorEl === langRef.current);
  const id = open ? 'lang-popover' : undefined;

  const handleClick = () => {
    setAnchorEl(langRef.current);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setAnchorEl(null);
  };

  const handleSelect = () => {
    if (isMobileView) {
      setAnchorEl(nextAnchor);
    } else {
      handleClose();
    }
  };

  const handleChange = (e) => {
    wrapedSynthetichandler(setSelectedLanguage)(e);
    handleSelect();
  };
  return (
    <>
      {isMobileView ? (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel className={classes.selectLabel} id="select-outlined-label">
            {localization.tours.availabilitySearch.language}
          </InputLabel>
          <Select
            native={true}
            inputProps={{
              name: localization.tours.availabilitySearch.language,
              id: 'outlined-age-native-simple',
            }}
            labelId="select-outlined-label"
            id="select-outlined"
            value={selectedLanguage}
            onChange={handleChange}
            className={classes.select}
            label={localization.tours.availabilitySearch.language}
          >
            <option aria-label="None" value="" />
            {languages.map((x) => (
              <option key={x.language} value={x.language}>
                {x.type} : {getLangName(x.language)}
              </option>
            ))}
          </Select>
        </FormControl>
      ) : (
        <TextField
          className={classes.formControl}
          label={localization.tours.availabilitySearch.language}
          value={
            selectedLanguage
              ? localization.language[selectedLanguage] || getLangName(selectedLanguage)
              : localization.tours.availabilitySearch.chooseLanguage
          }
          InputProps={{
            readOnly: true,
          }}
          classes={{
            root: classes.inputRoot,
          }}
          variant="outlined"
          color="primary"
          aria-describedby={id}
          onClick={handleClick}
          ref={langRef}
        />
      )}
      {!isMobileView && languages.length && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          <div className={classes.selectLangBlock}>
            <p className={classes.selectLangBlockTitle}>
              {localization.tours.availabilitySearch.chooseLanguage}
            </p>
            <FormControl className={classes.radioButtonsBlock} component="fieldset">
              <RadioGroup
                aria-label={localization.tours.availabilitySearch.chooseLanguage}
                name="lang"
                value={selectedLanguage}
                onChange={handleChange}
              >
                {languages.map((x) => (
                  <FormControlLabel
                    key={x.language}
                    value={x.language}
                    control={<Radio />}
                    label={x.type + ' : ' + getLangName(x.language)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </Popover>
      )}
    </>
  );
}

export default LanguagePicker;
