import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import BookedIcon from '../../../assets/img/trip-booked.png';

const offset = 190;
const duration = '1.4s';

const useStyles = makeStyles((theme) => ({
  bookedAnimationBlock: {
    position: 'relative',
    width: '160px',
  },
  bookedWrapper: {
    height: '122px',
    width: '122px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eff0f0',
    margin: 'auto',
    position: 'absolute',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    animation: `$bounce 300ms ${theme.transitions.easing.sharp} 1000ms`,
  },
  '@keyframes bounce': {
    '0%': {
      height: '122px',
      width: '122px',
    },
    '50%': {
      height: '132px',
      width: '132px',
    },
    '100%': {
      height: '122px',
      width: '122px',
    },
  },
  bookedImage: {
    marginTop: '6px',
    width: '88px',
    height: '88px',
    backgroundImage: `url(${BookedIcon})`,
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    animation: `$bounce-img 400ms ${theme.transitions.easing.sharp} 400ms`,
  },
  '@keyframes bounce-img': {
    '0%': {
      width: '88px',
      height: '88px',
    },
    '50%': {
      height: '66px',
      width: '66px',
    },
    '100%': {
      width: '88px',
      height: '88px',
    },
  },
  spinner: {
    width: 160,
    height: 160,
    animation: `$rotator 2000ms ${theme.transitions.easing.easeInOut}`,
  },
  '@keyframes rotator': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(270deg)',
    },
  },
  path: {
    stroke: '#004A97',
    strokeDasharray: offset,
    strokeDashoffset: 0,
    transformOrigin: 'center',
    animation: `$dash ${duration} ${theme.transitions.easing.easeInOut}`,
  },

  '@keyframes dash': {
    '0%': { strokeDashoffset: offset },
    '50%': {
      strokeDashoffset: offset / 4,
      transform: 'rotate(135deg)',
    },
    '100%': {
      strokeDashoffset: offset,
      transform: 'rotate(450deg)',
    },
  },
}));

export const CircularAnimation = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.bookedAnimationBlock}>
        <svg className={classes.spinner} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle
            className={classes.path}
            fill="none"
            strokeWidth="1"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
        <div className={classes.bookedWrapper}>
          <div className={classes.bookedImage} />
        </div>
      </div>
    </>
  );
};
