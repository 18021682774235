import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import localTheme from 'theme';
import { setCurrency } from '../../currency';
import localization, { setLocalization } from '../../localization';
import Dropdown from '../Common/Dropdown';
import LanguageIcon from '../Common/LanguageIcon';
import CustomHelmet from './CustomHelmet';
import SearchBar from './SearchBar';

const poweredBy = require('../../assets/img/PoweredByLogo.svg');
const SingleViewImg = require('../../assets/img/SingleColumnView.svg');
const DoubleViewImg = require('../../assets/img/DoubleColumnView.svg');

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 9999,
    width: '100%',
  },
  defaultHeader: {
    position: 'relative',
    boxShadow: '0px 3px 2px 0px rgba(0, 0, 0, 0.1)',
    backgroundColor: `${
      localTheme.config.usePrimaryColoredBanner ? localTheme.colors.primary : '#ffffff'
    }`,
    zIndex: 99,
  },
  logoWrapper: {
    display: 'block',
    marginTop: '9px',
  },
  logoMobile: {
    display: 'block',
    width: '100%',
    maxWidth: '124px',
    height: 'auto',
    backgroundSize: '197px 45px',
    marginLeft: '5%',
  },
  desktopLogoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  desktopLogo: {
    display: 'block',
    height: localTheme.config.customLogoHeight || '39px',
    backgroundSize: '197px 45px',
    marginLeft: '5%',
    marginRight: '40px',
  },
  row: {
    width: '100%',
    height: '90px',
    margin: 'auto',
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchBarWrapper: (props) => ({
    marginRight: 0,
    maxWidth: '50%',
    marginTop: '10px',
    fontFamily: `${localTheme.fonts.primary}`,
    height: '40px',
    display: 'flex',
    justifyContent: `${props.isHomePage > -1 ? 'flex-end' : 'flex-start'}`,
    '& > div': {
      width: '100%',
    },
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      marginRight: '20px',
    },
  }),
  currencyConverter: {
    marginTop: `${localTheme.name === 'Campsited Tours' ? '0px' : '0px'}`,
    marginRight: '14px',
    width: `${localTheme.name === 'Campsited Tours' ? 'auto !important' : 'auto !important'}`,
  },
  currency: {
    color: 'black',
  },
  languageWrapper: {
    marginRight: '14px',
  },
  logo: {
    display: 'block',
    height: '39px',
    backgroundSize: '197px 45px',
    marginLeft: '5%',
  },
  mobileWrapper: {
    borderBottom: `solid 2px ${localTheme.colors.secondary}`,
    backgroundColor: '#ffffff',
    padding: '20px 50px',
    zIndex: 99,
  },
}));

const Header = ({ toggleView, filters, location, item, extraTrackingProperties }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const page = window.location.pathname.split('/')[1];
  const url = window.location.href;
  const isHomePage = ['home', ''].includes(page);
  const isSearchPage = window.location.href.includes('/search');
  const classes = useStyles({ isHomePage });

  const updateCurrencyStorage = () => {
    const values = queryString.parse(window.location.search);
    if (!localStorage.getItem('curr')) {
      localStorage.setItem('curr', localTheme.currency.defaultWithSymbol);
    }

    if (values.currency) {
      const searchedCurrency = values.currency.toUpperCase();
      const storageCurrency = localStorage.getItem('curr').substring(0, 3).toUpperCase();
      const isSearchedCurrAvailable = Object.keys(localTheme.currency.available).includes(
        searchedCurrency
      );

      if (searchedCurrency !== storageCurrency && isSearchedCurrAvailable) {
        const relevantCurrency = localTheme.currency.available[searchedCurrency];

        localStorage.setItem('curr', relevantCurrency);
        setCurrency(relevantCurrency);
      }
    }
  };

  useEffect(() => {
    updateCurrencyStorage();

    const updateWindowDimensions = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  let logoPath = ['home', ''].includes(page) ? localTheme.assets.logoWhite : localTheme.assets.logo;
  let logoStyle =
    ['home', ''].includes(page) && !localTheme.config.dontUseWhiteLogoOnHomepage
      ? { filter: 'contrast(0) brightness(100)' }
      : {};
  let poweredByStyle =
    ['home', ''].includes(page) || localTheme.config.usePrimaryColoredBanner
      ? { filter: 'contrast(0) brightness(100)' }
      : {};
  if (
    localTheme.name === 'Campsited Tours' ||
    windowWidth >= 800 ||
    (windowWidth < 800 && page !== 'search')
  ) {
    return (
      <div className={['home', ''].includes(page) ? classes.header : classes.defaultHeader}>
        <CustomHelmet page={page} item={item} url={url} location={location} />
        <div className={classes.row}>
          <NavLink className={classes.logoWrapper} to={'/home'}>
            {windowWidth < StyleBreakpoints.sm ? (
              <img
                src={logoPath}
                style={logoStyle}
                className={classes.logoMobile}
                alt="Mobile Logo"
              />
            ) : (
              <div className={classes.desktopLogoWrapper}>
                <img
                  src={logoPath}
                  style={logoStyle}
                  className={classes.desktopLogo}
                  alt="Desktop Logo"
                />
                <img src={poweredBy} style={poweredByStyle} alt="Powered By" />
              </div>
            )}
          </NavLink>
          <div className={classes.searchBarWrapper}>
            {page !== 'vcheckout' && page !== 'vorder-confirmation' && (
              <div className={classes.currencyConverter}>
                <Dropdown
                  onChange={setCurrency}
                  values={Object.values(localTheme.currency.available)}
                  selectedValue={localStorage.getItem('curr').split(' ')[0]}
                  renderValue={(curr) => <span className={classes.currency}>{curr}</span>}
                  align="left"
                />
              </div>
            )}
            <span className={classes.languageWrapper}>
              <Dropdown
                onChange={setLocalization}
                values={localTheme.language.available}
                selectedValue={localization.getLanguage()}
                renderValue={(lang) => <LanguageIcon language={lang} size={23} />}
              />
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <CustomHelmet page={page} item={item} url={url} location={location} />
        <div className={classes.mobileWrapper}>
          {window.localStorage.getItem('view') === '"single"' && !filters && isSearchPage && (
            <img
              className={classes.logo}
              style={{ display: 'flex', float: 'left', marginLeft: '2%', width: '90px' }}
              src={SingleViewImg}
              onClick={toggleView}
              alt="Single View"
            />
          )}
          {(window.localStorage.getItem('view') === '"double"' ||
            !window.localStorage.getItem('view')) &&
            !filters &&
            isSearchPage && (
              <img
                className={classes.logo}
                style={{ display: 'flex', float: 'left', marginLeft: '2%', width: '90px' }}
                src={DoubleViewImg}
                onClick={toggleView}
                alt="Double View"
              />
            )}

          <Link to={'/home'}>
            <img
              className={classes.logo}
              style={{ display: 'flex', float: 'center', margin: 'auto' }}
              src={logoPath}
              alt="Logo"
            />
          </Link>
        </div>
        <SearchBar
          focus={isSearchFocused}
          setFocus={(isSearchFocused) => setIsSearchFocused({ isSearchFocused })}
          mobile={true}
          extraTrackingProperties={{
            ...extraTrackingProperties,
            'display area': 'header bar',
          }}
        />
      </div>
    );
  }
};

export default Header;
