import { Checkbox, List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isArray } from 'lodash';
import * as React from 'react';
import { FontSizes } from '../../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  checkBox: {
    marginRight: '5px',
  },
  label: {
    fontSize: FontSizes.cta,
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '15px',
    cursor: 'pointer',
    padding: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: '-9px',
  },
});

export default function TagFilters({ tags, handleChange, selectedItems }) {
  const classes = useStyles();

  function checkIfTagIsChecked(tag) {
    if (isArray(selectedItems) && tag?.tagId) {
      return selectedItems.indexOf(tag.tagId) !== -1;
    } else {
      return false;
    }
  }
  return (
    <List className={classes.root}>
      {tags.slice(0, 10).map((tag) => {
        const tagText = tag.displayText;
        const tagId = tag.id;
        const labelId = `checkbox-list-label-${tagText}`;

        return (
          <ListItem
            className={classes.label}
            key={tagId}
            role={undefined}
            dense
            onClick={() => handleChange(tag)}
          >
            <Checkbox
              className={classes.checkBox}
              checked={checkIfTagIsChecked(tag)}
              tabIndex={-1}
              disableRipple={true}
              inputProps={{ 'aria-labelledby': labelId }}
            />

            <div className={classes.text} id={labelId}>
              {tagText}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
