import React from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import SwiperCore, { FreeMode, Keyboard, Navigation, Scrollbar } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { getTopAttractions } from '../../api/attractions';
import LeftArrow from '../../assets/img/icons/LeftIcon.svg';
import RightArrow from '../../assets/img/icons/RightIcon.svg';
import AttractionCard from './AttractionCard';
import DestinationCard from './DestinationCard';

SwiperCore.use([Keyboard, Scrollbar, Navigation, FreeMode]);

class Places extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attractions: [],
      destinations: props.destinations || [],
      isAttractions: props.isAttractions || false,
    };
  }
  async componentWillMount() {
    if (this.state.isAttractions) {
      let attractions = await getTopAttractions();
      this.setState({
        attractions,
      });
    }
  }

  async componentWillReceiveProps(props) {
    if (props.destinations) {
      this.setState({
        destinations: props.destinations,
      });
    }
  }

  render() {
    const attractionList = this.state.attractions.map((a, index) => {
      return (
        <SwiperSlide style={{ width: 'fit-content' }} key={index}>
          <AttractionCard attraction={a} mobile={isMobile} isClickable={this.state.isMoving} />
        </SwiperSlide>
      );
    });

    const destinationList = this.state.destinations.map((d, index) => {
      return (
        <SwiperSlide style={{ width: 'fit-content' }} key={index}>
          <DestinationCard destination={d} mobile={isMobile} isClickable={this.state.isMoving} />
        </SwiperSlide>
      );
    });

    const contentList = this.state.isAttractions ? attractionList : destinationList;

    const params = {
      slidesPerView: 'auto',
      loopedSlides: 100,
      grabCursor: true,
      freeMode: {
        enabled: true,
        momentumBounce: false,
      },
      keyboard: { enabled: true },
      loop: true,
      preventClicks: true,
      spaceBetween: 26,
      breakpoints: {
        1280: {
          spaceBetween: 35,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    return (
      <Swiper {...params} style={{ paddingBottom: '15px' }}>
        {contentList}
        <BrowserView>
          <img
            src={RightArrow}
            style={{ right: '0px', width: '40px', height: '40px', top: '42%' }}
            className="swiper-button-next"
            alt={'Right Arrow'}
          ></img>
          <img
            src={LeftArrow}
            style={{ left: '0px', width: '40px', height: '40px', top: '42%' }}
            className="swiper-button-prev"
            alt={'Left Arrow'}
          ></img>
        </BrowserView>
      </Swiper>
    );
  }
}

export default Places;
