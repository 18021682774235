import { getExperimentOption } from './analytics';

export const LOCATION_CARD_SIZE_V1 = {
  name: 'location-card-size-v1',
  values: {
    SmallCard: 'small card',
    LongCard: 'long  card',
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? LOCATION_CARD_SIZE_V1.values.SmallCard
      : LOCATION_CARD_SIZE_V1.values.LongCard;
  },
};

export function isLongTourCard() {
  return getExperimentOption(LOCATION_CARD_SIZE_V1.name) === LOCATION_CARD_SIZE_V1.values.LongCard;
}

export const TOUR_CARD_BOOK_NOW_BUTTON_V1 = {
  name: 'tour-card-book-now-button-v1',
  values: {
    BookNowButton: 'book now button',
    NoButton: 'no button',
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? TOUR_CARD_BOOK_NOW_BUTTON_V1.values.BookNowButton
      : TOUR_CARD_BOOK_NOW_BUTTON_V1.values.NoButton;
  },
};

export const LOCATION_PAGE_SORT_ORDER_V1 = {
  name: 'location-page-sort-order-v1',
  values: {
    Featured: 'Featured',
    PriceLowHigh: 'Price low - high',
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? LOCATION_PAGE_SORT_ORDER_V1.values.Featured
      : LOCATION_PAGE_SORT_ORDER_V1.values.PriceLowHigh;
  },
};

export function isBookNowButton() {
  return (
    getExperimentOption(TOUR_CARD_BOOK_NOW_BUTTON_V1.name) ===
    TOUR_CARD_BOOK_NOW_BUTTON_V1.values.BookNowButton
  );
}

export const REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1 = {
  name: 'reinforcement_messages_homepage_location_v1',
  values: {
    Below_Banner: 'Reinforcement Messages Directly Below Banner',
    Below_Destinations: 'Reinforcement Messages Below Top Destinations',
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1.values.Below_Banner
      : REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1.values.Below_Destinations;
  },
};

export const TOUR_COUNTDOWN_V1 = {
  name: 'tour_countdown_v1',
  values: {
    WithoutCountdown: 'Without countdown',
    Countdown10Minutes: 'Countdown 10 Minutes',
    Countdown15Minutes: 'Countdown 15 Minutes',
    Countdown20Minutes: 'Countdown 20 Minutes',
  },
  generateExperimentSetting: () => {
    const randomValue = Math.random();
    if (randomValue <= 0.25) {
      return TOUR_COUNTDOWN_V1.values.WithoutCountdown;
    } else if (randomValue >= 0.26 && randomValue <= 0.5) {
      return TOUR_COUNTDOWN_V1.values.Countdown10Minutes;
    } else if (randomValue >= 0.51 && randomValue <= 0.75) {
      return TOUR_COUNTDOWN_V1.values.Countdown15Minutes;
    } else if (randomValue >= 0.76) {
      return TOUR_COUNTDOWN_V1.values.Countdown20Minutes;
    }
  },
};

export const TOUR_AVAILABILITY_OPTIONS_STYLE_V1 = {
  name: 'tour_availability_options_style_v1',
  values: {
    Light: 'light',
    Dark: 'dark',
    Default: 'default',
  },
  generateExperimentSetting: () => {
    const randomValue = Math.random();
    if (randomValue <= 0.33) {
      return TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Light;
    } else if (randomValue >= 0.34 && randomValue <= 0.66) {
      return TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Dark;
    } else if (randomValue >= 0.67) {
      return TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Default;
    }
  },
};

export function isDarkOptions() {
  return (
    getExperimentOption(TOUR_AVAILABILITY_OPTIONS_STYLE_V1.name) ===
    TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Dark
  );
}

export function isLightOptions() {
  return (
    getExperimentOption(TOUR_AVAILABILITY_OPTIONS_STYLE_V1.name) ===
    TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Light
  );
}

export function isCardedOptions() {
  return isDarkOptions() || isLightOptions();
}

export function isDefaultOptions() {
  return (
    getExperimentOption(TOUR_AVAILABILITY_OPTIONS_STYLE_V1.name) ===
    TOUR_AVAILABILITY_OPTIONS_STYLE_V1.values.Default
  );
}

export const TOUR_EXPANDABLE_OPTIONS_V1 = {
  name: 'tour_expandable_options_v1',
  values: {
    Expandable: 'Expandable',
    Default: 'default',
  },
  generateExperimentSetting: () => {
    return Math.random() >= 0.5
      ? TOUR_EXPANDABLE_OPTIONS_V1.values.Expandable
      : TOUR_EXPANDABLE_OPTIONS_V1.values.Default;
  },
};

export function isExpandableOptions() {
  return (
    getExperimentOption(TOUR_EXPANDABLE_OPTIONS_V1.name) ===
    TOUR_EXPANDABLE_OPTIONS_V1.values.Expandable
  );
}

export default [
  LOCATION_PAGE_SORT_ORDER_V1,
  LOCATION_CARD_SIZE_V1,
  TOUR_COUNTDOWN_V1,
  REINFORCEMENT_MESSAGES_HOMEPAGE_LOCATION_V1,
  TOUR_AVAILABILITY_OPTIONS_STYLE_V1,
  TOUR_EXPANDABLE_OPTIONS_V1,
  TOUR_CARD_BOOK_NOW_BUTTON_V1,
];
