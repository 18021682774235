import { compose } from 'lodash/fp';
import React, { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import ReactDOM from 'react-dom';
import { GoSearch } from 'react-icons/go';
import OutsideClickHandler from 'react-outside-click-handler';
import { connect } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent } from '../../assets/img/Add.svg';
import localization from '../../localization';
import { FETCH_SEARCH_PROMPTS } from '../../store/search/actionTypes';
import { Analytics } from '../../utils/analytics';
import { hex2rgb, rgb2str } from '../../utils/styles';
import { makeStyles } from '@mui/styles';

import PinSVG from '../../assets/img/LocationIcon.svg';

var img = require('../../assets/img/assets/searchIcon2.PNG');
const Shadow = compose(rgb2str, (x) => ({ ...x, a: 0.25 }), hex2rgb);

const MobileSearchIcon = styled(GoSearch)`
  margin-left: 15px;
  min-width: 20px;
  height: 60px;
  @media (max-width: 475px) {
    height: 40px;
    min-width: 15px;
  }
  color: black;
`;

const CancelIcon = styled(ReactComponent)`
  transform: rotate(45deg);
  position: absolute;
  right: 5px;
  top: 33%;
  cursor: pointer;
  #Oval {
    transition: stroke 0.2s ease-in-out;
    stroke: #d3d3d4;
  }
  #Plus {
    fill: #d3d3d4;
    transition: fill 0.2s ease-in-out;
  }
  :hover {
    #Oval {
      stroke: red;
    }
    #Plus {
      fill: red;
    }
  }
`;
const TypeaheadCommon = styled(AsyncTypeahead)`
  width: 100%;
  @media (max-width: 375px) {
    display: none;
    width: 0;
  }
  input {
    width: 100%;
    height: 40px;
    display: block;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    box-sizing: border-box!important;
    border: 1px solid #ced4da;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    
  @media (max-width: 650px) {
    font-size: 12px !important;
  }
`;

const TypeaheadCommonNoSearchButton = styled(AsyncTypeahead)`
  width: 100%;

  input {
    border: none;
    width: 100%;
    height: 40px;
    display: block;
    padding: 29px;

    @media (max-width: 475px) {
      height: 39px;
      padding: 0px;
      padding-left: 10px;
    }
    border-radius: 4.5px;
    box-sizing: border-box !important;
  }
`;

const TypeaheadFocused = styled(TypeaheadCommon)`
  @media (max-width: 375px) {
    display: block;
    width: 100%;
  }
  input,
  .form-control:focus {
    outline: 0;
  }
`;

const TypeaheadFocusedNoSearchButton = styled(TypeaheadCommonNoSearchButton)`
  #search-bar {
    z-index: 9999;
    left: -35px !important;
    width: calc(100% + 35px) !important;
  }
  @media (max-width: 375px) {
    display: block;
    width: 100%;
  }
  input,
  .form-control:focus {
    outline: 0;
  }
`;

const SearchButtonCommon = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 40px;
  height: 40px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
  }
`;

const SearchButtonFocused = styled(SearchButtonCommon)`
  box-shadow: 0.1rem 0 0 0.1rem ${(props) => Shadow(props.theme.colors.secondary)};
  border-color: ${(props) => props.theme.colors.secondary};
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 60px;

  @media (max-width: 475px) {
    height: 40px;
  }
  .rbt-input-hint-container {
    overflow: hidden;
  }
  .rbt-input-hint-container input {
    color: #424242;
    @media (max-width: 650px) {
      font-size: 16px;
      transform: scale(0.75);
      transform-origin: left center;
      margin-right: -33.33%;
      box-shadow: none;
    }
  }
`;
const InputWrapperMob = styled.div`
  margin-top: 3%;
  border: 1px solid #919191;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const InputMob = styled.input`
  width: 85%;
  height: 42px;
  border: none;
  font-style: MontserratBold;
  font-weight: bold;
`;

const MobileSearchBarCommon = styled.div`
  width: 100%;
  display: flex;
  height: 44px;
  padding: 1px;
`;

const MobileSearchBarFocused = styled.div`
  width: 100%;
  display: flex;
  border: solid 1px;
  height: 44px;
  border-radius: 4px;
  border-color: transparent;
  outline: 0;
`;

const useStyles = makeStyles({
  hovered: {
    '& .active': {
      color: '#16181b',
      textDecoration: 'none',
      backgroundColor: '#e9ecef!important',
    },
  },
});

const Cancel = ({ focus, clear }) => {
  if (!focus) {
    return null;
  }
  const mount = document.getElementsByClassName('rbt-input-hint-container')[0];
  //HOTFIX for autocomplete text getting misaligned
  // document.getElementsByClassName('rbt-input-hint')[0].style['margin-top'] = '-7px';
  if (!mount) {
    return null;
  }
  return ReactDOM.createPortal(<CancelIcon onClick={clear} />, mount);
};

const getWindowDimensions = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  return {
    width,
    height,
  };
};

const SearchBar = ({
  initValue,
  history,
  focus,
  setFocus,
  mobile,
  searchByQuery,
  searchByLocationId,
  searchByTourId,
  hideSearchIcon,
  notifyLocationChange,
  notifyTourChange,
  notifySearchChange,
  placeholder,
  searchPrompts,
  popularDestinations,
  fetchSearchPrompts,
  extraTrackingProperties,
}) => {
  const [query, setQuery] = useState(initValue);
  const [destinationId, setDestinationId] = useState(null);
  const [destinationName, setDestinationName] = useState(null);
  const [tourId, setTourId] = useState(null);
  const [tourName, setTourName] = useState(null);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const [searchValue, setSearchValue] = useState('');
  const [prompts, setPrompts] = useState(searchPrompts);

  const classes = useStyles();
  const inputRef = useRef();
  let location = useLocation().pathname.split('/')[1];

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (focus && inputRef && inputRef.current) {
      inputRef.current.getInstance().focus();
    }
  });

  useEffect(() => {
    if (notifySearchChange) {
      notifySearchChange(query);
    }
  });

  useEffect(() => {
    if (notifyLocationChange) {
      notifyLocationChange(destinationId, destinationName);
    }
  }, [destinationId, destinationName]);

  useEffect(() => {
    if (notifyTourChange) {
      notifyTourChange(tourId, tourName);
    }
  }, [tourId, tourName]);

  const reset = () => {
    /*
    if (inputRef && inputRef.current) {
      inputRef.current.getInstance().clear();
    }
    setDestinationId(null);
    setTourId(null);

    setQuery('');
    setFocus(false);*/
  };

  const Typeahead = hideSearchIcon
    ? focus
      ? TypeaheadFocusedNoSearchButton
      : TypeaheadCommonNoSearchButton
    : focus
    ? TypeaheadFocused
    : TypeaheadCommon;
  const MobileSearchBar = focus ? MobileSearchBarFocused : MobileSearchBarCommon;
  const SearchButton = focus ? SearchButtonFocused : SearchButtonCommon;

  const searchHandler = () => {
    if (!focus) {
      return setFocus(true);
    }

    if (destinationId) {
      return searchByLocationId(history, destinationId);
    }

    if (tourId) {
      return searchByTourId(history, tourId);
    }

    return searchByQuery(history, query);
  };

  useEffect(() => {
    if (searchValue.length < 1) {
      setPrompts(popularDestinations);
    } else {
      setPrompts(searchPrompts);
    }
  }, [searchPrompts, popularDestinations, searchValue]);

  const handleTypeaheadKeyDown = (e) => {
    e.persist();

    switch (e.keyCode) {
      case 13: {
        const activeLoc = prompts.find((prompt) => prompt.name === e.target.value);

        if (activeLoc?.location_id) {
          searchByLocationId(history, activeLoc.location_id);
        } else if (activeLoc?.tourId) {
          searchByTourId(history, activeLoc.tourId);
        }

        break;
      }
    }
  };

  const handleSearch = (searchString) => {
    setQuery(searchString);
    fetchSearchPrompts({ query: searchString, language: localization.getLanguage() });

    Analytics.track('fetch search prompts', {
      ...extraTrackingProperties,
      'search term': searchString,
      'display area': 'home search',
    });
  };

  const handleRenderMenu = (results, menuProps) => {
    const searchTerm = menuProps.text;
    const exactMatch = results.find((e) => e.english_name === searchTerm || e.name === searchTerm);

    if (exactMatch) {
      results = [exactMatch].concat(
        results.filter((e) => e.english_name != searchTerm || e.name != searchTerm)
      );
    }

    return <Menu {...menuProps}>{mapMenuItems(results)}</Menu>;
  };

  const handleOnTypeaheadChange = ([option]) => {
    Analytics.track('search option selected', {
      ...extraTrackingProperties,
      'destination id': option.location_id,
      'destination name': option.location_id ? option.name : null,
      'tour id': option.tourId,
      'tour name': option.tourId ? option.name : null,
    });

    if (option && option.location_id) {
      setDestinationId(option && option.location_id);
      setDestinationName(option && option.name);
    } else if (option && option.tourId) {
      setTourId(option && option.tourId);
      setTourName(option && option.name);
    }
  };

  const handleFocused = () => {
    Analytics.track('Search bar focused', {
      ...extraTrackingProperties,
      'display area': 'header bar',
    });
  };

  const mapMenuItems = (results) => {
    return results.map((result, index) => {
      const title = result.name;
      const locationObj = prompts[index];

      if (result.header === true) {
        return results.length > 1 ? (
          <li key={index} style={{ color: 'black' }} className="dropdown-header">
            {title}
          </li>
        ) : (
          <li className="disabled">
            <div className="dropdown-item disabled">
              {localization.header.searchbar.notMatchFound}
            </div>
          </li>
        );
      }

      if (locationObj) {
        if (locationObj.type) {
          if (
            locationObj?.type.toUpperCase() === 'CITY' ||
            locationObj?.type.toUpperCase() === 'POI'
          ) {
            return (
              <MenuItem key={index} position={index} option={result} className={classes.hovered}>
                <img style={{ marginRight: '14px', height: '25px' }} src={PinSVG} alt="Pin SVG" />
                <b style={{ marginTop: '10px' }}>{title}</b>
              </MenuItem>
            );
          }
        }
      }

      return (
        <MenuItem key={index} option={result} position={index} className={classes.hovered}>
          <div>{result.name}</div>
        </MenuItem>
      );
    });
  };

  if (!mobile || (mobile && location !== 'search')) {
    return (
      <OutsideClickHandler onOutsideClick={() => setFocus(false)}>
        <InputWrapper onFocus={() => setFocus(true)}>
          <MobileSearchBar>
            <MobileSearchIcon />
            <Typeahead
              ref={inputRef}
              id="search-bar"
              useCache
              onKeyDown={handleTypeaheadKeyDown}
              selectHintOnEnter
              labelKey="name"
              placeholder={placeholder ?? localization.header.searchbar.placeholder}
              onSearch={handleSearch}
              options={prompts}
              minLength={0}
              onInputChange={setSearchValue}
              delay={300}
              renderMenu={handleRenderMenu}
              onFocus={handleFocused}
              onChange={handleOnTypeaheadChange}
            />
          </MobileSearchBar>
          <Cancel focus={focus} clear={reset} />
          {hideSearchIcon ? null : (
            <SearchButton onClick={searchHandler}>
              <GoSearch style={{ color: 'white' }} alt="search" />
            </SearchButton>
          )}
        </InputWrapper>
      </OutsideClickHandler>
    );
  } else {
    if (location == 'search') {
      return (
        <InputWrapperMob>
          <img src={img} style={{ marinBottom: '7px', marginRight: '4%' }} />
          <InputMob placeholder="Search"></InputMob>
        </InputWrapperMob>
      );
    }
  }
};

export default withRouter(
  connect(
    (state) => ({
      popularDestinations: state.location.cities.list,
      searchPrompts: state.search.promptsList.sort((a, b) => {
        const map = {};
        map['CITY'] = 1;
        map['city'] = 1;
        map['AREA'] = 2;
        map['area'] = 2;
        map['POI'] = 3;
        map['poi'] = 4;

        if (map[a.type] < map[b.type]) {
          return -1;
        }

        if (map[a.type] > map[b.type]) {
          return 1;
        }

        return 0;
      }),
    }),
    (dispatch) => ({
      searchByQuery: (history, query) => history.push(`/search/${query}`),
      searchByLocationId: (history, locationId) => history.push(`/location/${locationId}`),
      searchByTourId: (history, locationId) => history.push(`/tour/${locationId}`),
      fetchSearchPrompts: (payload) => dispatch({ type: FETCH_SEARCH_PROMPTS, payload }),
    })
  )(SearchBar)
);
