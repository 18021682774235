import { StyleBreakpoints } from '@constants';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { displayOnlyCurrencies } from '../../utils/displayOnlyCurrencies';
import { Button } from './index';

let DownArrow = require('../../assets/img/assets/DownArrow.svg');

const DropdownWrapper = styled.div`
  display: inline-flex;
  vertical-align: middle;
  position: relative;
`;

const DropdownButton = styled(Button)`
  width: 100%;
  height: 38px;
  color: #333333;
  font-size: ${FontSizes.p};
  font-weight: 600;
  border-radius: 4.5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  min-width: 75px;
  font-family: MontserratBold;
  justify-content: space-between;

  :hover {
    color: #ffffff;
    background-color: #f3f3f3;
  }

  :focus {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  @media screen and (min-width: ${StyleBreakpoints.xs}px) {
    min-width: 85px;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  min-width: fit-content;
  display: ${props => (props.hidden ? 'none' : 'initial')};
  float: left;
  margin: 0.125rem 0 0;
  padding: 8px 0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const Element = styled.button`
  display: block;
  width: 100%;
  padding: 8px 10px;
  clear: both;
  color: #333333;
  font-size: ${FontSizes.p};
  font-weight: 600;
  text-align: inherit;
  white-space: nowrap;
  background-color: #fafafa;
  font-family: MontserratBold;
  border: 0;
  :hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f3f3f3;
  }
  :active {
    color: #fff;
    text-decoration: none;
    background-color: #f3f3f3;
  }
`;

export function renderMessage(value) {
  const languageName = value.split(' ')[0].toLowerCase();

  if (value.indexOf(displayOnlyCurrencies[`${languageName}`]) > -1) {
    return (
      <div style={{ width: '110px', whiteSpace: 'normal' }}>{localization.dropDown.payInEur}</div>
    );
  } else {
    return null;
  }
}

export default ({ onChange, title, selectedValue, values, renderValue, align = 'left' }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <OutsideClickHandler onOutsideClick={() => setHidden(true)}>
      <DropdownWrapper style={{ width: 'auto' }}>
        <DropdownButton onClick={() => setHidden(!hidden)}>
          {title ? title : renderValue(selectedValue)}
          <img src={DownArrow} alt="" />
        </DropdownButton>
        <Menu hidden={hidden}>
          {values.map((value, i) => (
            <Element
              style={{ textAlign: align }}
              onClick={() => {
                onChange(value);
                setHidden(true);
              }}
              key={i}
            >
              {renderValue(value)}
              {renderMessage(value)}
            </Element>
          ))}
        </Menu>
      </DropdownWrapper>
    </OutsideClickHandler>
  );
};
