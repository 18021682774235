import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { SvgColorHelper } from '../../../Common';
import categoryIconsById from '../Icons';
import styles from './tag.module.scss';

const ActiveCard = styled.div`
  border: solid 2px ${props => props.theme.colors.primary}!important;
`;

const CategoryName = styled.div`
  text-align: center;
  width: 100%;
  color: black;
  font-weight: bold;
  font-family: MontserratBold;
`;

function Tag({ name, icon = categoryIconsById.default, isSelected, handleTagSelection }) {
  const CardIcon = SvgColorHelper('CategoryCardIcon', icon, theme.colors.primary);

  const MAX_LEN = 30;
  let formattedTitle = name.length > MAX_LEN ? name.substring(0, MAX_LEN) + '...' : name;

  return (
    <>
      {isSelected ? (
        <ActiveCard className={styles.tagCard} onClick={handleTagSelection}>
          <CategoryName className={styles.tagName}>{formattedTitle}</CategoryName>
        </ActiveCard>
      ) : (
        <div className={styles.tagCard} onClick={handleTagSelection}>
          <CategoryName className={styles.tagName}>{formattedTitle}</CategoryName>
        </div>
      )}
    </>
  );
}
export default Tag;
